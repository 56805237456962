<template>
    <div class="box">
        <div class="top_box">
            <LeftBoxVue @changeTips="changeTips"></LeftBoxVue>
            <RightBoxVue></RightBoxVue>
        </div>
        <BottomBoxVue></BottomBoxVue>
        <TopTips :tips="tips"></TopTips>
    </div>
</template>

<script>
import BottomBoxVue from '@/components/BottomBox.vue';
import LeftBoxVue from '@/components/LeftBox.vue';
import RightBoxVue from '@/components/RightBox.vue';
import TopTips from '@/components/TopTips.vue'

export default {
    data() {
        return {
            tips: false
        }
    },
    components: {
        BottomBoxVue,
        LeftBoxVue,
        RightBoxVue,
        TopTips
    },
    methods: {
        changeTips() {
            this.tips = true
        }
    }
}

</script>

<style scoped>
.box {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);

    .top_box {
        position: relative;
        display: flex;
        flex-flow: row-reverse;
        width: 100%;
        /* height: calc(100% - 120px); */
        height: calc(100% - 7.5rem);
    }
}

@media screen and (max-width:768px) {
    .box {
        position: static;
        height: auto;

        .top_box {
            display: block;
            height: 100%;
            border-bottom: 0px;
            border-bottom: 1px solid #fff;
        }
    }
}
</style>
