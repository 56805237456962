<template>
  <div class="left_box">
    <div class="btn_box" v-if="tokenId == 0">
      <button class="login_btn" @click="LoginWallet">CONNECT WALLET</button>
    </div>
    <div class="tip_box" v-if="tokenId == 1">
      <p class="login_NFT">
        You don’t have YuGiYn <br />
        Genesis NFT in your wallet.
      </p>
    </div>
    <div class="NFT_box" v-if="tokenId == 2">
      <p class="title">Select NFTs to reveal:</p>
      <div :class="centerStyle ? 'show_box' : 'show_box pc_show_box'">
        <div class="show_item" v-for="(item, index) of tokenList" :key="index">
          <div class="item_img">
            <img class="big_img" :src="item.metadata.image" alt="" />
            <div class="show_apply">
              <span>{{ item.metadata.name }}</span>
            </div>
          </div>
          <div class="item_check">
            <input class="active_check" type="checkbox" @click="ActiveNFT($event, item.id)" />
          </div>
        </div>
      </div>
      <div class="show_btn">
        <button class="rev_btn" :disabled="isButtonDisabled" @click="reveal()">REVEAL</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Web3 from "web3";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init('61676f64663d2c330aa1914cdf73942e');

export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      web3: null,
      checkList: [],
      tokenId: 0,
      address: "",
      tokenList: [],
      centerStyle: null,
      isButtonDisabled: false,
      count: 0
    };
  },
  computed: {},
  watch: {},
  mounted() { },
  methods: {
    async LoginWallet() {
      if (window.ethereum) {
        amplitude.track("web3_login");
        const ehtres = await window.ethereum.enable();
        this.address = ehtres[0];
        this.web3 = new Web3(window.ethereum);
        const res = await axios.get(
          `https://vs-backend.yu-gi-yn.com/api/addresses/${this.address}/tokens`
        );
        if (res.data.data.length == 0) {
          this.tokenId = 1;
        } else {
          this.tokenId = 2;
          this.tokenList = res.data.data;
          if (res.data.data.length < 4) {
            this.centerStyle = false;
          } else {
            this.centerStyle = true;
          }
        }
      } else {
        alert("Please install the Metamask wallet");
      }
    },
    ActiveNFT(e, index) {
      if (e.target.checked) {
        if (this.checkList.length < 10) {
          this.checkList.push(index);
        } else {
          alert('Select a maximum of 10 NFTs per time')
        }
      } else {
        this.checkList = this.checkList.filter((item) => item !== index);
      }
    },
    async reveal() {
      const that = this;
      if (that.checkList.length == 0) {
        return alert('Please select the NFT that requires Reveal')
      }
      that.isButtonDisabled = true
      const newDate = new Date().toISOString();
      const web3Value =
        "The signature is used for YuGiYin's NFT drawing. After you sign, it represents your agreement to this operation.\n" + 'timestamp=' + newDate;
      try {
        const signDate = await that.web3.eth.personal.sign(
          web3Value,
          that.address,
          newDate
        );
        const resultTimer = setInterval(async () => {
          that.count++
          const result = await axios.post("https://backend.yu-gi-yn.com/reveal.json", {
            tokenIds: that.checkList,
            message: web3Value,
            signature: signDate,
          })
          if (that.count > 2) {
            that.isButtonDisabled = false
            that.$emit("changeTips");
            return clearInterval(resultTimer)
          }
        }, 2000)
      } catch (error) {
        that.isButtonDisabled = false
        if (that.count > 2) {
          that.$emit("changeTips");
          clearInterval(resultTimer)
        }
      }
    },
  },
};
</script>
<style scoped>
.left_box {
  position: relative;
  width: 50%;
  height: 100%;

  .btn_box {
    .login_btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 17.1875rem;
      height: 3.75rem;
      background-color: #fff;
      cursor: pointer;
      border: 0;
      font-size: 1.375rem;
      line-height: 1.15;
      text-align: center;
      font-family: "SemiBold";
    }
  }

  .login_NFT {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    width: 20.375rem;
    font-size: 1.75rem;
    line-height: 1.15;
    font-family: "SemiBold";
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }

  .NFT_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 80px 5rem;
    box-sizing: border-box;

    .title {
      font-size: 1.75rem;
      color: #fff;
      line-height: 1.75;
      font-family: "SemiBold";
    }

    .show_box {
      display: flex;
      flex-wrap: wrap;
      width: 30rem;
      height: 23.5625rem;
      padding: 2.5rem 1.875rem 0rem 1.875rem;
      background-color: #fff;
      overflow-x: hidden;
      box-sizing: border-box;

      &.pc_show_box {
        justify-content: space-around;
      }

      &::-webkit-scrollbar {
        width: 0.375rem;
        background-color: #e5e5e5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000;
        border-radius: 0.375rem;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0.75rem;
        /* background-color: #e5e5e5; */
      }

      .show_item {
        width: 5.9375rem;
        margin-bottom: 1.25rem;
        margin-right: 0.5rem;

        &:nth-child(4n) {
          margin-right: 0;
        }

        .item_img {
          position: relative;
          height: 7.625rem;
          box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
          border-radius: 0.75rem;
          overflow: hidden;

          .big_img {
            width: 5.9375rem;
            height: 5.9375rem;
          }

          .show_apply {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 1.6875rem;
            font-size: 0.625rem;
            font-family: "SemiBold";
            text-align: center;

            span {
              background-color: pink;
              line-height: 1.15;
            }
          }
        }

        .item_check {
          margin-top: 0.625rem;
          text-align: center;

          .active_check {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin: -0.3em 0.25em 0 0;
            padding: 0;
            width: 0.875rem;
            height: 0.875rem;
            border: 0.0625rem solid #ccc;
            font-size: 0.9em;
            cursor: pointer;
            outline: none;
            transition: all 0.2s ease;
            border-radius: 50%;

            &::after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 50%;
              top: 50%;
              background: #000;
              border-radius: 50%;
              width: 0;
              height: 0;
              opacity: 0;
              transform: translate(-50%, -50%);
              transition: all 0.2s ease;
              transform-origin: center center;
              pointer-events: none;
            }

            &:checked::after {
              width: 0.5rem;
              height: 0.5rem;
              opacity: 1;
            }
          }
        }
      }
    }

    .show_btn {
      width: 30rem;
      margin-top: 2.5rem;
      text-align: center;

      .rev_btn {
        width: 17.1875rem;
        height: 3.75rem;
        border: 0.0625rem solid #fff;
        background-color: transparent;
        color: #fff;
        font-size: 2.5rem;
        font-family: "SemiBold";
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .left_box {
    width: 100%;
    height: auto;

    .btn_box {
      position: relative;
      width: 100%;
      height: 260px;
      padding-top: 80px;

      .login_btn {
        width: 275px;
        height: 60px;
        font-size: 22px;
      }
    }

    .tip_box {
      position: relative;
      width: 100%;
      height: 260px;
      padding-top: 80px;

      .login_NFT {
        width: 100%;
        font-size: 22px;
      }
    }

    .NFT_box {
      position: initial;
      width: 100%;
      height: auto;
      padding: 0;
      transform: translate(0, 0);

      .title {
        padding: 35px;
        font-size: 22px;
      }

      .show_box {
        width: 100%;
        height: auto;
        padding: 35px 16px;
        overflow-y: hidden;

        &.pc_show_box {
          justify-content: normal;
        }

        .show_item {
          width: 105px;
          margin-right: 14px;

          &:nth-child(4n) {
            margin-right: 14px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }

          .item_img {
            position: relative;
            height: 133px;

            .big_img {
              width: 105px;
              height: 105px;
            }

            .show_apply {
              height: 28px;
              font-size: 11px;
            }
          }
        }
      }

      .show_btn {
        padding: 35px 0rem;
        width: 100%;
        margin: 0;

        .rev_btn {
          width: 275px;
          height: 60px;
          font-size: 24px;
        }
      }
    }
  }
}
</style>