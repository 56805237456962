<template>
  <div :class="tips ? 'tips_box active_revel' : 'tips_box'">
    <div class="tips_wirte">
      <div class="tips_title">
        <p>Your NFTs have been revealed.</p>
        <p>Visit your OpenSea NFT pages and click</p>
        <p>“Refresh metadata”.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {

  },
  mixins: [],
  props: {
    tips: {
      type: Boolean
    }
  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style scoped>
.tips_box {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 99;
  transition: all .6s linear;

  &.active_revel {
    top: 0;
  }

  .tips_wirte {
    position: relative;
    width: 100%;
    height: 50%;
    background-color: #fff;
    z-index: 9;

    .tips_title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      p {
        font-size: 1.75rem;
        text-align: center;
        font-family: 'Medium';
      }
    }


  }
}

@media screen and (max-width:768px) {
  .tips_box {
    .tips_wirte {
      .tips_title {
        width: 100%;

        p {
          font-size: 22px;
          line-height: 27px;
        }

      }
    }
  }
}
</style>