<template>
  <div id="app">
    <HomeView />
  </div>
</template>

<script>
import HomeView from './views/HomeView.vue'

export default {
  name: 'App',
  data() {
    return {

    }
  },
  components: {
    HomeView
  },
  methods: {

  }
}
</script>

<style>

</style>
