<template>
  <div class="bottom_box">
    <img class="ygy_logo" @click="YuGiYnOfficial()" src="@/assets/images/Logo.png" alt="">
    <div class="bottm_icon">
      <img class="icon_inner" @click="openNewPages('opensea')" src="@/assets/images/Opensea.png" alt="">
      <img class="icon_inner" @click="openNewPages('discord')" src="@/assets/images/Discord.png" alt="">
      <img class="icon_inner" @click="openNewPages('twitter')" src="@/assets/images/Twitter.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    openNewPages(tag) {
      if (tag == 'opensea') {
        window.open('https://opensea.io/collection/yugiyn-official', '_blank')
      } else if (tag == 'discord') {
        window.open('https://discord.gg/yugiyn', '_blank')
      } else {
        window.open('https://twitter.com/Yu_Gi_Yn', '_blank')
      }
    },
    YuGiYnOfficial() {
      window.open('https://yu-gi-yn.com/', '_blank')
    }
  }
};
</script>
<style scoped>
.bottom_box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #000;
  border-top: .0625rem solid #fff;
  height: 7.5rem;
  z-index: 9;


  .ygy_logo {
    width: 4rem;
    height: 3.75rem;
    margin-left: 1.875rem;
    margin: 1.875rem 0 1.875rem 1.875rem;
    object-fit: contain;
    cursor: pointer;
  }

  .bottm_icon {
    display: flex;
    margin: 2.1875rem 2.1875rem 2.1875rem 0;

    .icon_inner {
      width: 3.125rem;
      height: 3.125rem;
      margin-left: 2.1875rem;
      cursor: pointer;
      object-fit: contain;
    }
  }
}

@media screen and (max-width:48rem) {
  .bottom_box {
    position: static;
    width: 100%;
    height: 4.375rem;

    .ygy_logo {
      width: 2.6875rem;
      height: 2.5rem;
      padding: .9375rem 0 .9375rem 1.25rem;
      margin: 0;
    }

    .bottm_icon {
      padding: 1.4063rem 1.4063rem 1.4063rem 0;
      margin: 0;

      .icon_inner {
        width: 1.5625rem;
        height: 1.5625rem;
        margin-left: 1.4063rem;
      }
    }
  }
}
</style>