<template>
  <div class="right_box">
    <img class="right_nft pc" src="@/assets/images/NFT.png" alt="">
    <img class="right_nft phone" src="@/assets/images/PhoneNFT.png" alt="">
  </div>
</template>
<script>
export default {
  name: '',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style scoped>
.right_box {
  position: relative;
  width: 50%;
  height: 100%;

  .right_nft {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 43.6875rem;
    height: 36.25rem;
    max-height: 85.3%;
    object-fit: contain;
  }

  .right_nft.pc {
    display: block;
  }

  .right_nft.phone {
    display: none;
  }
}

@media screen and (max-width:48rem) {
  .right_box {
    width: 100%;
    height: auto;
    text-align: center;

    .right_nft {
      position: static;
      width: 100%;
      height: 278px;
    }

    .right_nft.pc {
      display: none;
    }

    .right_nft.phone {
      display: block;
    }
  }
}
</style>