import { render, staticRenderFns } from "./BottomBox.vue?vue&type=template&id=641d2263&scoped=true"
import script from "./BottomBox.vue?vue&type=script&lang=js"
export * from "./BottomBox.vue?vue&type=script&lang=js"
import style0 from "./BottomBox.vue?vue&type=style&index=0&id=641d2263&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641d2263",
  null
  
)

export default component.exports